import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EsaAlertComponent } from './esa-alert.component';

@Injectable({
  providedIn: 'root'
})
export class EsaAlertService {
  private currentModal: NgbModalRef | null = null;

  constructor(
    private ngbModal: NgbModal
  ) { }

  private alert(type: 'success' | 'error' | 'upgrade', messages?: string | string[]): void {
    if (typeof messages === 'string') {
      messages = [messages];
    }

    if (this.currentModal) {
      this.currentModal.close();
    }

    this.currentModal = this.ngbModal.open(EsaAlertComponent, { centered: true });
    this.currentModal.componentInstance.type = type;
    this.currentModal.componentInstance.messages = messages;

    this.currentModal.result.finally(() => {
      this.currentModal = null;
    });
  }

  success(messages: string | string[]): void {
    this.alert('success', messages);
  }

  error(messages: string | string[]): void {
    this.alert('error', messages);
  }

  upgrade(): void {
    this.alert('upgrade');
  }
}

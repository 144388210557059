import { EsaAlertService } from 'src/app/utils/modules/esa-alert/esa-alert.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { IEmpresa } from 'src/app/shared/models/Empresa';
import { SubjectService } from 'src/app/core/services/company/subject.service';
import { SignatureService } from '../services/signature.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpService } from 'src/app/core/services/http/http.service';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  latitude,
  longitude,
} from 'src/app/core/services/geoLocalizacao/Gelocalizacao';
import { environment } from '../../../../../../environments/environment';



export class AuthService {
  constructor() {
    import('src/assets/mp.js').then(result => {
    })
  }
}

declare const LoadbricksFull: any;
declare const UmountBrick: any;


@Component({
  selector: 'app-pagamento-mp',
  templateUrl: './pagamento-mp.component.html',
  styleUrls: ['./pagamento-mp.component.scss'],
})

export class PagamentoMpComponent implements OnInit {
  location: Location;
  assinaturaCancelada = false;
  formularioCartao: UntypedFormGroup;
  errorMessage = '';
  successMessage = '';
  nometitularpagamento = new UntypedFormControl(null, [Validators.required]);
  CPF_CNPJ = new UntypedFormControl(null, [Validators.required]);
  email = new UntypedFormControl(null, [Validators.required, Validators.email]);
  numeroCartao = new UntypedFormControl(null, [Validators.required, Validators.min(0)]);
  validadeCartao = new UntypedFormControl(null, [Validators.required]);
  cvvCartao = new UntypedFormControl(null, [Validators.required]);
  cartaoCredito: any;
  empresa: IEmpresa;
  loading = false;
  url: string = "";
  urlSafe: SafeResourceUrl;
  planPayment: string = "";
  planPaymentValor: any;
  latitude: string;
  longitude: string;
  http: any;
  httpRequest: HttpRequest<unknown>;
  httpHandler: HttpHandler;
  token: string;
  usertoken: string;
  mpToken: string;
  formIsCreated = false;

  constructor(
    private authenticationService: AuthenticationService,
    private signatureService: SignatureService,
    private subjectService: SubjectService,
    private esaAlertService: EsaAlertService,
    private httpService: HttpService,
    location: Location,
    public sanitizer: DomSanitizer,
    private router: Router,
    private activatedRoute: ActivatedRoute

  ) {
    this.latitude = latitude;
    this.longitude = longitude;
    this.activatedRoute.queryParams.subscribe(params => {
      let UrlplanPayment = params['plan'];
      this.planPayment = UrlplanPayment;
    });
  }

  intercept(
    httpRequest: HttpRequest<unknown>,
    httpHandler: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authentication = this.authenticationService.getAuthentication();
    if (authentication?.access_token) {
      httpRequest = httpRequest.clone({
        setHeaders: {
          Authorization: `Bearer ${authentication.access_token}`,
          latitude: this.latitude,
          longitude: this.longitude,
        },
      });
    }
    return httpHandler.handle(httpRequest);
  }

  ngOnInit(): any {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.getAuthenticationService();
    this.buscarDadosEmpresa();
  }

  async montaFormularioMercadoPago() {
    this.iniciarFormulario();

    this.signatureService.mostrarMenuAssinatura = true;
    this.mpToken = environment.mercadoPagoToken;

    UmountBrick();

    await this.signatureService.buscarPlanoPorGatewayId(this.planPayment).subscribe(({ dados }) => {
      LoadbricksFull(environment.mercadoPagoToken, this.planPayment, this.planPaymentValor, this.usertoken);
      this.formIsCreated = true;
    });
  }

  getAuthenticationService(): void {
    const authentication = this.authenticationService.getAuthentication();
    this.usertoken = authentication.access_token;
  }

  buscarDadosEmpresa() {
    this.subjectService.empresa.subscribe((empresa) => {
      this.empresa = empresa as IEmpresa;
      if (empresa != null &&  this.formIsCreated == false) {
        this.planPaymentValor = empresa?.assinatura?.valor;
        this.montaFormularioMercadoPago();
      }
    });
  }

  iniciarFormulario(): void {
    this.formularioCartao = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      number: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
      validade: new UntypedFormControl(null, Validators.required),
      cvv: new UntypedFormControl(null, Validators.required),
    });
  }

  get dadosEmpresaCadastrados(): boolean {
    return this.empresa?.cnpj ? true : false;
  }
  cancelar(): void {
    this.location.back();
  }
  cadastrarCartao(): void {
    const form = this.formularioCartao.value;
    const body = {
      ...form,
      expYear: form.validade.slice(2),
      expMonth: form.validade.slice(0, 2),
    };
    delete body.validade;
    this.loading = true;
    this.signatureService.cadastrarCartao(body).subscribe({
      next: ({ dados }) => {
        this.empresa.assinatura.formaPagamento = 1;
        this.formularioCartao.reset();
        this.cartaoCredito = dados;
        this.loading = false;
      },
      error: (_) => (this.loading = false),
    });
  }


  efetuarpagamento(): void {
    if (this.email.invalid) return;
    this.successMessage = this.errorMessage = '';
    const form = this.formularioCartao.value;
    const body = {
      ...form,
    };
    this.signatureService.efetuarpagamento(body, this.planPayment).subscribe({
      next: ({ dados }) => {
        this.successMessage = dados;
      },
      error: (_) => (this.loading = false),
    });
  }

  get conteudoBotaoCartao(): string {
    const formaPagamento = this.empresa?.assinatura?.formaPagamento;
    if (formaPagamento == null) return 'Finalizar assinatura';
  }
  mask: string;

  cpfcnpjmask() {
    const value = this.formularioCartao.get('cpf_cnpj').value;
    if (value.length <= 14) {
      this.mask = '00.000.000/0000-00'
    }
    else {
      this.mask = '00.000.0000-00'
    }
  }
}

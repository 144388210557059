import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { EsaAlertComponent } from './esa-alert.component';

@NgModule({
  declarations: [
    // components
    EsaAlertComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    // components
    EsaAlertComponent
  ]
})
export class EsaAlertModule { }

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AgGridModule } from 'ag-grid-angular';


// locale
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt, 'pt');

// modules
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MegaleiosAlertModule } from './utils/modules/megaleios-alert/megaleios-alert.module';
import { EsaAlertModule } from './utils/modules/esa-alert/esa-alert.module';
import { NgxMaskModule } from 'ngx-mask';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { PagamentoMpComponent } from './modules/private/modules/signature/pagamento-mp/pagamento-mp.component';
import { ErrorHandler, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import {AngularTreeGridModule} from 'angular-tree-grid';

// interceptors
import { JsonWebTokenInterceptor } from './core/interceptors/json-web-token/json-web-token.interceptor';
import { ErrorInterceptor } from './core/interceptors/error/error.interceptor';

import { environment } from '../environments/environment';

// containers
import { AppComponent } from './app.component';
import { GridModule } from '@progress/kendo-angular-grid';


import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG  } from "ng2-currency-mask";

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};
@NgModule({
  declarations: [AppComponent , PagamentoMpComponent],
  imports: [
    ButtonsModule,
    InputsModule,
    BrowserModule,
    AgGridModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RecaptchaV3Module,
    MatDialogModule,
    FormsModule,
    // modules
    HttpClientModule,
    MegaleiosAlertModule,
    EsaAlertModule,
    MatTableModule,
    NgxMaskModule.forRoot(),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({ maxAge: 10, logOnly: environment.production }),
    GridModule,
    CurrencyMaskModule,

  ],
  providers: [
    // locale
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    // interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonWebTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    /* Tiny MCE */
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    /*recaptcha */
    {
      provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey,
    },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
  ],  schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
  exports:[ MatTableModule ],
})
export class AppModule {}

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-esa-alert',
  templateUrl: './esa-alert.component.html',
  styleUrls: ['./esa-alert.component.scss'],
})
export class EsaAlertComponent {

  @Input()
  type: 'success' | 'error' | 'upgrade' = 'success';

  @Input()
  messages: string[];

  constructor(
    public ngbActiveModal: NgbActiveModal
  ) { }

}

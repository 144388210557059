import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import {
  latitude,
  longitude,
} from '../../services/geoLocalizacao/Gelocalizacao';

@Injectable()
export class JsonWebTokenInterceptor implements HttpInterceptor {
  latitude: string;
  longitude: string;

  constructor(private authenticationService: AuthenticationService) {
    this.latitude = latitude;
    this.longitude = longitude;
  }

  intercept(
    httpRequest: HttpRequest<unknown>,
    httpHandler: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authentication = this.authenticationService.getAuthentication();
    if (authentication?.access_token) {
      httpRequest = httpRequest.clone({
        setHeaders: {
          Authorization: `Bearer ${authentication.access_token}`,
          latitude: this.latitude,
          longitude: this.longitude,
        },
      });
    }
    return httpHandler.handle(httpRequest);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';

import { catchError, retry, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { MegaleiosAlertService } from 'src/app/utils/modules/megaleios-alert/megaleios-alert.service';
import { EsaAlertService } from './../../../../../utils/modules/esa-alert/esa-alert.service';

@Injectable({
  providedIn: 'root',
})
export class SignatureService {
  private _mostrarMenuAssinatura = new BehaviorSubject<boolean>(true);
  private baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private megaleiosAlertService: MegaleiosAlertService,
    private esaAlertService: EsaAlertService
  ) {}

  get mostrarMenuAssinatura(): any {
    return this._mostrarMenuAssinatura.asObservable();
  }

  set mostrarMenuAssinatura(value: any) {
    this._mostrarMenuAssinatura.next(value);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }

  cancelarAssinatura(body: object): Observable<any> {
    return this.http
      .put<any>(this.baseUrl + '/api/v1/Assinaturas/Cancelamento', body)
      .pipe(
        tap(
          (data) => of(data),
          ({ mensagens }) => this.esaAlertService.error(mensagens)
        )
        //retry(1)
        //catchError(this.handleError)
      );
  }

  escolherMetodoPagamento(body: object): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + '/api/v1/Usuario/CancelarAssinatura', body)
      .pipe(
        tap(
          (data) => of(data),
          ({ mensagens }) => this.megaleiosAlertService.error(mensagens)
        ),
        retry(1)
      );
  }

  solicitarConsultoria(body: object): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + '/api/v1/Empresa/SolicitarConsultoria', body)
      .pipe(
        tap(
          ({ mensagens }) => this.megaleiosAlertService.success(mensagens),
          ({ mensagens }) => this.megaleiosAlertService.error(mensagens)
        )
      );
  }

  comprarUsuario(body: object): Observable<any> {
    return this.http
      .put<any>(this.baseUrl + '/api/v1/Assinaturas/ComprarUsuarios', body)
      .pipe(
        tap(
          (data) => of(data),
          ({ mensagens }) => this.megaleiosAlertService.error(mensagens)
        )
      );
  }

  buscarCartaoCredito(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/api/v1/CartaoCredito').pipe(
      tap(
        (data) => of(data),
        ({ mensagens }) => this.megaleiosAlertService.error(mensagens)
      ),
      retry(1)
    );
  }

  buscarPlanos(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/api/v1/Planos').pipe(
      tap(
        (data) => of(data),
        ({ mensagens }) => this.megaleiosAlertService.error(mensagens)
      ),
      retry(1)
    );
  }

  buscarPlanoPorId(planoId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/api/v1/Planos/busca-plano-individual/' + planoId).pipe(
      tap(
        (data) => of(data),
        ({ mensagens }) => this.megaleiosAlertService.error(mensagens)
      ),
      retry(1)
    );
  }

  buscarPlanoPorGatewayId(gatewayId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/api/v1/Planos/busca-plano-gateway/' + gatewayId).pipe(
      tap(
        (data) => of(data),
        ({ mensagens }) => this.megaleiosAlertService.error(mensagens)
      ),
      retry(1)
    );
  }

  cadastrarCartao(body: object): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + '/api/v1/CartaoCredito', body)
      .pipe(
        tap(
          (data) => {
            this.megaleiosAlertService.success(
              'Cartão adicionado com sucesso!'
            );
            return of(data);
          },
          ({ mensagens }) => this.esaAlertService.error(mensagens)
        )
        //retry(1)
      );
  }

  efetuarpagamento(body: object, preapProvalPlanId: string): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + '/api/v1/payment/' + preapProvalPlanId +'/process-payment', body)
      .pipe(
        tap(
          (data) => {
            this.megaleiosAlertService.success(
              'Pagamento efetuado com sucesso!'
            );
            return of(data);
          },
          ({ mensagens }) => this.esaAlertService.error(mensagens)
        )
        //retry(1)
      );
  }

  alterarFormaPagamento(body: object): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + '/api/v1/Assinaturas/AlterarPagamento', body)
      .pipe(
        tap(
          (data) => {
            this.megaleiosAlertService.success(
              'Forma de pagamento alterada com sucesso!'
            );
            return of(data);
          },
          ({ mensagens }) => this.megaleiosAlertService.error(mensagens)
        )
      );
  }

  buscarBoleto(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/api/v1/Assinaturas/Boleto').pipe(
      tap(
        (boleto) => {
          this.megaleiosAlertService.success(
            'Verificado se possui boleto disponível'
          );
          return of(boleto);
        },
        ({ mensagens }) => this.esaAlertService.error(mensagens)
      )
    );
  }

  buscarFaturas(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/api/v1/Empresa/Faturas').pipe(
      tap(
        (data) => of(data),
        ({ mensagens }) => this.megaleiosAlertService.error(mensagens)
      ),
      retry(1),
      catchError(this.handleError)
    );
  }

  detalheFatura(id: string): Observable<any> {
    return this.http
      .get<any>(this.baseUrl + '/api/v1/Empresa/Faturas/' + id)
      .pipe(
        tap(
          (data) => of(data),
          ({ mensagens }) => this.megaleiosAlertService.error(mensagens)
        )
      );
  }

  fazerUpgrade(body: object): Observable<any> {
    return this.http
      .put<any>(this.baseUrl + '/api/v1/Assinaturas/Upgrade', body)
      .pipe(
        tap({
          next: (_) =>
            this.megaleiosAlertService.success(
              'Upgrade realizado com sucesso!'
            ),
          error: ({ mensagens }) => this.megaleiosAlertService.error(mensagens),
        })
      );
  }

  solicitarSegundaViaBoleto(body: object): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + '/api/v1/Empresa/SolicitarSegundaViaBoleto',
      body
    );
  }

  solicitarModelosDeDocumento(body: object): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + '/api/v1/Empresa/SolicitarModelos',
      body
    );
  }

  solicitarAgendamentoBackup(): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/api/v1/Backup', null);
  }

  solicitarBackupsAgendados(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/api/v1/Backup');
  }

  solicitarArquivoBackup(id: string): Observable<HttpEvent<any>> {
    const url = `${this.baseUrl}/api/v1/Backup/${id}`;
    const req = new HttpRequest('GET', url, {
      reportProgress: true,
      responseType: 'arraybuffer',
    });

    return this.http.request(req);
  }

  criarAssinatura(body): Observable<any>{
    return this.http.post(this.baseUrl + '/api/v1/Assinaturas/cria-assinatura', body);
  }
  
  buscarBoletos(): Observable<any>{
    return this.http.get(this.baseUrl + '/api/v1/Assinaturas/FaturaLista');
  }
}

import { EsaAlertService } from './../../../utils/modules/esa-alert/esa-alert.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from '../../services/http/http.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private esaAlertService: EsaAlertService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((responseOne: any) => {
        if (responseOne.status === 401) {
          this.esaAlertService.error(
            responseOne.error.mensagens || 'Ocorreu um erro'
          );
          this.router.navigate(['/login']);
          /* const value = {
            refreshToken: this.authenticationService.getAuthentication()
              .refresh_token,
          };
          return this.httpService.post('usuario/token', value).pipe(
            tap(() => this.authenticationService.unsetAuthentication()),
            flatMap((responseTwo: any) => {
              this.authenticationService.setAuthentication(responseTwo.data);
              req = req.clone({
                setHeaders: {
                  Authorization: `bearer ${responseTwo.data.access_token}`,
                },
              });
              return next.handle(req);
            }),
            catchError((responseTwo: any) => {
              this.router.navigate(['/login']);
              return throwError(responseTwo.error);
            })
          ); */
        }
        return throwError(responseOne.error || responseOne);
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private router: Router) { }

  public jwtHelper: JwtHelperService = new JwtHelperService();

  setAuthentication(authentication: any): void {
    sessionStorage.setItem('authentication', authentication);
    localStorage.setItem('authentication', authentication);
  }

  getAuthentication(): any {
    const localStorageAuthentication = localStorage.getItem('authentication');
    const sessionStorageAuthentication =
      sessionStorage.getItem('authentication');
    return (
      JSON.parse(localStorageAuthentication) ||
      JSON.parse(sessionStorageAuthentication)
    );
  }

  unsetAuthentication(): void {
    localStorage.removeItem('authentication');
    sessionStorage.clear();
  }

  isLoggedIn(): boolean {
    const sessionStorageAuthentication = sessionStorage.getItem('authentication');
    const localStorageAuthentication = localStorage.getItem('authentication');

    if (sessionStorageAuthentication || localStorageAuthentication) {

      //commentado, esta parte desolga o usuário se ficar muito tempo sem fazer login
      //porém a camila pediu para não fazer esta regra por enquanto.
      // if (this.canActivate()) {
      //   return true;
      // } else {
      //   this.unsetAuthentication();
      //   return false;
      // }
      return true;
    } else {
      this.unsetAuthentication();
      return false;
    }
  }

  canActivate() {
    const token = JSON.parse(sessionStorage.getItem("authentication")).access_token;

    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    } else {
      this.unsetAuthentication();
      return false;
    }
  }
}

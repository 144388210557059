export let latitude = '';
export let longitude = '';
export let locatizacaoCompartilhada: boolean;

function solicitarLocatizacao() {
  if (navigator.geolocation)
    navigator.geolocation.getCurrentPosition(exibirCoordenadas, verificarErro);
  else throw new Error('Seu navegador não possui suporte para geolocalização');
}

function exibirCoordenadas({ coords }) {
  latitude = coords?.latitude + '';
  longitude = coords?.longitude + '';
  locatizacaoCompartilhada = true;
}

function verificarErro(error) {
  if (error.code == error.PERMISSION_DENIED) {
      locatizacaoCompartilhada = false;
  }
}

solicitarLocatizacao();

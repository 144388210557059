import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import Swal from 'sweetalert2';

import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PrivateGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    _route?: ActivatedRouteSnapshot,
    routerStateSnapshot?: RouterStateSnapshot
  ): boolean {
    if (this.authenticationService.isLoggedIn()) {
      return true;
    } else {
      // Swal.fire({
      //   icon: 'info',
      //   title: 'Confirmação de acesso',
      //   html: `
      //   <h2>Faça login para continuar</h2>
      //   <small> <i>Você será redirecionado(a) automaticamente ao fazer login</i> </small>`,
      // });
      this.router.navigate(['/login'], {
        queryParams: { redirectTo: routerStateSnapshot.url },
      });
      return false;
    }
  }
}

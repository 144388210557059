import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions, NoPreloading } from '@angular/router';

// guards
import { PrivateGuard } from './core/guards/private.guard';

const routes: Routes = [
  {
    path: 'conta',
    loadChildren: () =>
      import('./modules/private/private.module').then((m) => m.PrivateModule),
    canActivate: [PrivateGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  relativeLinkResolution: 'legacy',
  preloadingStrategy: NoPreloading
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

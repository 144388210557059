import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IEmpresa } from 'src/app/shared/models/Empresa';
import { INotificacao } from 'src/app/shared/models/Notificacao';
import { IUsuario } from 'src/app/shared/models/Usuario';
import { IDadosNotificacoes } from './../../../shared/models/Notificacao';
import { environment } from 'src/environments/environment';
import { OnDestroyClass } from 'src/app/utils/classes/on-destroy.class';
@Injectable({
  providedIn: 'root',
})
export class SubjectService {
  constructor(private http: HttpClient) {}
  private baseUrl: string = environment.baseUrl;
  private _empresa = new BehaviorSubject<IEmpresa>(null);
  private _usuario = new BehaviorSubject<IUsuario>(null);
  private _notificacoes = new BehaviorSubject<INotificacao>(null);
  private _dadosNotificacoes = new BehaviorSubject<IDadosNotificacoes>(null);
  private _lerQRCode = new BehaviorSubject<boolean>(false);

  get lerQRCode(): any {
    return this._lerQRCode.asObservable();
  }

  set lerQRCode(value: any) {
    this._lerQRCode.next(value);
  }

  get empresa(): any {
    return this._empresa.asObservable();
  }

  set empresa(empresa: any) {
    this._empresa.next(empresa);
  }

  get notificacoes(): any {
    return this._notificacoes.asObservable();
  }

  set notificacoes(notificacoes: any) {
    this._notificacoes.next(notificacoes);
  }

  get usuario(): any {
    return this._usuario.asObservable();
  }

  set usuario(usuario: any) {
    this._usuario.next(usuario);
  }

  get dadosNotificacoes(): any {
    return this._dadosNotificacoes.asObservable();
  }

  set dadosNotificacoes(dadosNotificacoes: any) {
    this._dadosNotificacoes.next(dadosNotificacoes);
  }

  buscarDadosEmpresa(): Observable<any> {
    return this.http.get(this.baseUrl + '/api/v1/Empresa/Detalhe').pipe(
      tap((res) => {
        this.empresa = res['dados'];
      })
    );
  }

  buscarDadosUsuario(): Observable<any> {
    return this.http.get(this.baseUrl + '/api/v1/Usuario/MeusDados').pipe(
      tap((res) => {
        this.usuario = res['dados'];
      })
    );
  }
}
